import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SlidingImg from '../../../assets/images/sliding-gates-img.jpg'

class SlidingGates extends React.Component {
	render() {
		const siteTitle = 'Sliding Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Get a Custom Sliding Gate in Dallas-Fort Worth, TX. Call  - We Build Our Automatic Gates in Arlington, TX and Install Anywhere in DFW'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Sliding Gates in Arlington, TX</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<p>
								J & J Gates Service and Design proudly offers a wide selection
								of sliding gates and operators in Dallas and Fort Worth, TX. We
								have designs that match the architecture of any home, with
								options in high-grade aluminum, high-quality wood and ornamental
								wrought iron. Whether you need a small pedestrian gate for your
								front yard or garden, or a full size entry gate for your
								property, you can count on our experts for design, installation
								and repair.
							</p>
							<h5>High-Quality</h5>
							<p>
								We can build a sliding gate that is manual or automatic, made of
								wood or metal such as aluminum chain link or wrought iron that
								will be stylish, durable, and affordable. All our sliding gates
								in Fort Worth and Dallas, TX are made from high-quality
								materials, so they can withstand the harsh Texas elements and
								require virtually no annual maintenance.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid"
								src={SlidingImg}
								alt="Sliding Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h5>Openers / Operators</h5>
							<p>
								We also offer a wide selection of{' '}
								<Link to="/gates/services/gate-automation/">
									automatic gate openers
								</Link>{' '}
								and{' '}
								<Link to="/gates/services/access-systems/">access systems</Link>
								, which allow us to customize for your specific needs. Slide
								gate operators are the top choice for homes in Arlington, TX and
								the Dallas-Fort Worth area when limited space applies. We
								suggest you give us a call to discuss gate safety. Depending on
								the amount of use, over time, slide gate operators can require
								maintenance. We provide affordable service and repair of sliding
								gates in Dallas and Fort Worth, TX. Our technicians can diagnose
								problems quickly and will keep it performing correctly.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Sliding Gate Installation & Repair</h4>
							<p>
								Our selection ranges from simple single sliding to customized
								dual sliding entry gates, all of which can be easily installed.
								Whether you are looking for custom built or pre-fabricated, we
								can assist you in selecting the correct solution for your home
								or business needs. Our team of professional installers is ready
								to tackle your project. Let us advise you on the best gate for
								your unique needs. To find out more{' '}
								<Link to="/contact-us">contact us today!</Link>
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SlidingGates

export const SlidingGatesPageQuery = graphql`
	query SlidingGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
